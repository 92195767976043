<template>
  <b-modal
    :ref="'prediction-details-modal'"
    ok-only
    ok-title="Accept"
    centered
    size="xl"
    title=""
  >
    <b-row>
      <b-col>
        <bruna-element-loader :dataUrl="detallePrediccionChart">
        </bruna-element-loader>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <ag-grid-table
          :configUrl="detallePrediccionTableConfig"
          :dataUrl="detallePrediccionTableData"
          :editUrl="detallePrediccionTableEdit"
        >
        </ag-grid-table>
      </b-col>
    </b-row>

    <!--
      <b-row>
        <b-col>
          <b-card-text>{{ cellValue.data }}</b-card-text>
        </b-col>
      </b-row>
        -->
  </b-modal>
</template>
    <script>   



import
{
  BRow,
  BCol,

} from "bootstrap-vue";

import useApiServices from '@/services/useApiServices.js';

import AgGridTable from '@/views/brunacomponents/ag-grid-table/AgGridTable.vue'
import BrunaElementLoader from '@/views/brunacomponents/BrunaElementLoader.vue'

export default {
  data()
  {
    return {

      detallePrediccionChart: null,
      detallePrediccionTableConfig: null,
      detallePrediccionTableData: null,
      detallePrediccionTableEdit: null


    };
  },
  components: {
    BRow,
    BCol,
    AgGridTable,
    BrunaElementLoader
  },

  directives: {
  },

  mounted()
  {

  },

  methods: {

    show(data)
    {

      let prediction_id = data.id

      this.detallePrediccionChart = useApiServices.detallePrediccionChart + '/' + prediction_id
      this.detallePrediccionTableConfig = useApiServices.detallePrediccionTableConfig + '/' + prediction_id
      this.detallePrediccionTableData = useApiServices.detallePrediccionTableData + '/' + prediction_id
      this.detallePrediccionTableEdit = useApiServices.detallePrediccionTableEdit + '/' + prediction_id


      this.$refs['prediction-details-modal'].show()



    }


  },
};
    </script>
    
    <style scoped>
.carga-data {
  margin-top: 4%;
}
</style>
    
    